import React, { Component } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import Home from './components/Home/Home';
import LayoutDefault from './components/layouts/LayoutDefault';
import LearningPlansDetails from "./components/LearningPlans/LearningPlansDetails";

class Routes extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <LayoutDefault>
                            <Home />
                        </LayoutDefault>
                    </Route>
                    <Route path="/:id">
                        <LayoutDefault>
                            <LearningPlansDetails />
                        </LayoutDefault>
                    </Route>
                    <Route path="*" render={() => <Redirect to="/erro/404" />} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routes;