import React, {Component} from 'react';
import {Container, Col} from 'reactstrap';
import Footer from './Footer';
//import Sidebar from './Sidebar';
import Header from './Header';
import AlertProvider from "../../providers/AlertProvider";
import '../../assets/css/styles.css';
class LayoutDefault extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = () => {
        let active = !this.state.collapsed;
        this.setState({collapsed: active});
    };

    render() {
        return (
            <div>
                <Header/>
                <div>
                    <AlertProvider>
                        {this.props.children}
                    </AlertProvider>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default LayoutDefault;
