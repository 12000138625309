import React, { Component } from 'react';
import {Container} from "reactstrap";
import {Link} from "react-router-dom";


class Header extends Component {
    render(){
        return(
            <header>
                <Container>
                    <Link to="/">
                        <img src="/img/logo.png" alt="Plano de aula"/>
                    </Link>
                </Container>
            </header>
        )
    }
}

export default Header;