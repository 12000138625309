import React, {Component} from 'react';
import { Label, Input } from 'reactstrap';
import {Col, Container, Row} from "reactstrap";
import ServicesLearningPlans from "../../services/LearningPlans/LearningPlansServices";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clean: "none" ,
            learningPlans: []
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        ServicesLearningPlans.getData(0, 1000, (data) => {
            this.setState({
                learningPlans: data
            });
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    }

    SearchPlan(target){
        var learningPlans = this.state.learningPlans
        var valor = target.value
        var procura = learningPlans.filter(x => x.name == valor)        
        this.props.setplansCallback(procura);
        this.setState({ clean: "block" })
    }

    cleanSearch(target){
        debugger
        var learningPlans = this.state.learningPlans
        this.props.setplansCallback(learningPlans);
        this.setState({ clean: "none" })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.SearchPlan(e.target);
        }
      }

    

    render() {
        var learningPlans = this.state.learningPlans
        var god = learningPlans.map(x => x.name)
        const clean = {           
            display: this.state.clean,            
        }
        return (
            <section className="search">
            <Container>
                <Row>
                    <Col xs={3}>
                        {/* <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-search"/>
                                </span>
                            </div>
                            <Input type="text" className="form-control"
                                   placeholder="Buscar" aria-label="Buscar"
                                   aria-describedby="basic-addon1"
                                   onChange={({target}) => this.SearchPlan(target)}/>
                        </div> */}
                        <section className="hold-search">
                            <Autocomplete
                            id="combo-box-demo"
                            options={god}
                            getOptionLabel={(option) => option}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Pesquisar" variant="outlined" 
                            onChange={({target}) => this.SearchPlan(target)}
                            onKeyDown={this._handleKeyDown}/>}
                            />
                            <div>
                                <i style={clean} onClick={({target}) => this.cleanSearch(target)} className="fa fa-times" aria-hidden="true"></i> 
                            </div>
                        </section>
                        
                    </Col>
                    <Col xs={6}/>
                    <Col xs={3}/>
                </Row>
            </Container>
        </section>

        )
    }
}

export default SearchBar;