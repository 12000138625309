import React, { Component, useState } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row} from 'reactstrap';

import LearningPlansUserRatingServices from "../../services/LearningPlansUserRating/LearningPlansUserRatingServices";
import {range} from "../../core/ranges/ranges";
import AADService from '../../services/AADService'
import {AlertContext} from "../../providers/AlertProvider";

class LearningPlanRatingModal extends Component {
    constructor(props) {
        super(props);
        this.AzureADService = new AADService();
        this.UserInfo = this.AzureADService.getUser();
        this.state = {
            planId: props.id,
            learningPlanName: props.learningPlanName,
            stars: props.stars,
            description: '',
            showModal: props.showModal,
            isUpdate: false,
            ratingId: ''
        };
    }

    renderStars = () => {
        const selectedStars = this.state.stars;
        const style = {
            color: 'gold'
        };
        return [...range(1, 5)].map((value, index) => {
            return <i className={`${((value <= selectedStars) ? "fas" : "far")} fa-star`}
                      style={style} onClick={() => this.changeStar(index)}/>
        });
    };

    changeStar = (index) => {
        this.setState({
            stars: index + 1
        });
    };

    handleInputChange(target) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const field = target.name;
        this.setState({
            [field]: value,
            invalid: {...this.state.invalid, [field]: target.required && !value},
        });

        if (target.id === 'description') {
            this.setState({
                description: value
            })
        }
    }

    componentDidMount() {      
       
        if (this.props.planId !== undefined) {
            LearningPlansUserRatingServices.getLearningPlansUserRating(this.props.planId, (data) => { 
                if(data != ""){                               
                    this.setState({
                        description : data.description,
                        isUpdate: true,
                        ratingId: data.id
                    })
                }

            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if(this.state.isUpdate){

            let dado = { 
                "id": this.state.ratingId,
                "name": this.state.name, 
                "createdBy": this.UserInfo.profile.name, 
                "createdByEmail": this.UserInfo.profile.unique_name, 
                "modifiedBy": this.UserInfo.profile.name, 
                "modifiedEmail": this.UserInfo.profile.unique_name,
                "active":  true ,
                "ratingstars" : this.state.stars,
                "learningPlansid": this.props.planId,
                "description": this.state.description,
                "emailadress": this.UserInfo.profile.unique_name,
                "name": this.state.learningPlanName
            }
            
            LearningPlansUserRatingServices.updateData(this.state.ratingId, dado, (data) => {
                
                this.showSuccessMessage("Classificação atualizada com sucesso!");                                 
                this.toggleModal()
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })

        }else{   
            let novodado = { 
                "name": this.state.name, 
                "createdBy": this.UserInfo.profile.name, 
                "createdByEmail": this.UserInfo.profile.unique_name, 
                "modifiedBy": this.UserInfo.profile.name, 
                "modifiedEmail": this.UserInfo.profile.unique_name,
                "active":  true ,
                "ratingstars" : this.state.stars,
                "learningPlansid": this.props.planId,
                "description": this.state.description,
                "emailadress": this.UserInfo.profile.unique_name,
                "name": this.state.learningPlanName
            }

            LearningPlansUserRatingServices.saveData(novodado, (data) => {     
                
                this.showSuccessMessage("Classificação salva com sucesso!");            
                this.toggleModal()
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            });
        }

    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    };

    onCloseModal = () => {        
        this.setState({
            stars: 0,
            comment: '',
            showModal: false
        });        
    };

    render() {
        return (
            <Modal isOpen={this.state.showModal} toggle={this.toggleModal} onClosed={this.props.onPressClose}>
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <ModalHeader>Classificação do plano de aula</ModalHeader>
                <ModalBody>
                    {this.renderStars()}
                    <Input type="textarea"
                           name="description"
                           id="description" placeholder="Escreva uma descrição"
                           value={this.state.description}
                           onChange={({target}) => this.handleInputChange(target)}
                           maxlength={1000}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.toggleModal()}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={(e) => this.handleSubmit(e)}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default LearningPlanRatingModal;