import React, {Component} from 'react';

import { Label, Input } from 'reactstrap';
import Home from './Home'

import LearningPlanServices from "../../services/LearningPlans/LearningPlansServices"
import LearningStepsServices from "../../services/LearningSteps/LearningStepsServices"
import DigitalToolsServices from "../../services/DigitalTools/DigitalToolsServices"
import ExperienceFieldsServices from "../../services/ExperienceFields/ExperienceFieldsServices"
import Disciplines from "../../services/Disciplines/DisciplinesServices"


import {AlertContext} from "../../providers/AlertProvider";

class FilterBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preencheu: 'não',
            Grades: [],
            learningPlans: [],
            learningSteps: [],
            digitalTools: [],
            experienceFields: [],
            disciplines: [],
            knowledgeArea: [],
            loading: false,

            learningStepsFilter: [],
            digitalToolsFilter: [],
            experienceFieldsFilter: [],
            disciplinesFilter:[],
            knowledgeAreaFilter:[],

            model: [],
            totalItens: 8,
            page: 0,
        }
    }

    // ===============================
    // Get Data
    // ===============================
    getLearningSteps = () => {        
        LearningStepsServices.getData(0, 100, (data) => {                        
            this.setState({
                learningSteps: data
            });          
        }, this.handleErrorMessage)
    };

    getDigitalTools = () => {
        DigitalToolsServices.getData(0, 1000, (data) => {
            this.setState({
                digitalTools: data
            });
        }, this.handleErrorMessage);
    };

    getExperienceFields = () => {
        ExperienceFieldsServices.getData(0, 1000, (data) => {
            this.setState({
                experienceFields: data
            });
        }, this.handleErrorMessage);
    };

    getDisciplines = () => {
        Disciplines.getData(0, 1000, (data) => {
            this.setState({
                disciplines: data
            });
        }, this.handleErrorMessage);
    };

    getknowledgeArea = () => {
        
        var arrayknowledgeArea = [
            {"id": 1, "name" : "Linguagens e suas tecnologias"},
            {"id": 2, "name" : "Matemática e suas tecnologias"},
            {"id": 3, "name" : "Ciências da natureza e suas tecnologias"},
            {"id": 4, "name" : "Ciências humanas e sociais aplicadas"},
            {"id": 5, "name" : "Formação técnica e profissional"},
            {"id": 6, "name" : "Linguagens"},
            {"id": 7, "name" : "Matemática"},
            {"id": 8, "name" : "Ciências da natureza"},
            {"id": 9, "name" : "Ciências humanas"},
            {"id": 10, "name" : "Ensino religioso"},
        ];

        arrayknowledgeArea = arrayknowledgeArea.sort((a, b) => a.name.localeCompare(b.name))

        this.setState({
            knowledgeArea: arrayknowledgeArea
        });
    };

    getLearningPlans = () => {        
        
        LearningPlanServices.getData(this.state.page, this.state.totalItens, (data) => {
            this.setState({
                learningPlans: data
            });            
            this.preencherModel()
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    }


    preencherModel(){
        
        let data = this.state.learningPlans
        if(this.state.preencheu == 'não'){
            var filterId = data.map(x => x.id)
            filterId.forEach((id) => 
            LearningPlanServices.getDetails(id, (data) => {
                    var juntaTudo = this.state.model.concat(data)
                    this.setState({
                        model: juntaTudo,
                        preencheu: 'sim'
                    });
            }))
        }
    }


    filterLearningPlans(parans, target) {
         
        let idFind = parans.id
        if(target.checked != false){
            if (target.id == 'learningSteps'){
                let learningSteps = this.state.learningStepsFilter;        
                learningSteps.push(idFind);
                this.setState({
                    learningStepsFilter : learningSteps
                });        
            }
            else if (target.id == 'digitaltools'){
                let digitaltools = this.state.digitalToolsFilter;        
                digitaltools.push(idFind);
                this.setState({
                    digitalToolsFilter : digitaltools
                });   
            }
            else if (target.id == 'experienceFields'){
                let experienceFields = this.state.experienceFieldsFilter;        
                experienceFields.push(idFind);
                this.setState({
                    experienceFieldsFilter : experienceFields
                });   
            }
            else if (target.id == 'knowledgeArea'){
                let knowledgeArea = this.state.knowledgeAreaFilter;        
                knowledgeArea.push(idFind);
                this.setState({
                    knowledgeAreaFilter : knowledgeArea
                });   
            }
            else if (target.id == 'disciplines'){
                let disciplines = this.state.disciplinesFilter;        
                disciplines.push(idFind);
                this.setState({
                    disciplinesFilter : disciplines
                });   
            }      
            
        }else{

            if (target.id == 'learningSteps'){
                let learningSteps = this.state.learningStepsFilter;
                var indice = learningSteps.indexOf(idFind);        
                learningSteps.splice(indice, 1);
                this.setState({
                    learningStepsFilter : learningSteps
                });
            }
            else if (target.id == 'digitaltools'){
                let digitaltools = this.state.digitalToolsFilter;
                var indice = digitaltools.indexOf(idFind);        
                digitaltools.splice(indice, 1);
                this.setState({
                    digitalToolsFilter : digitaltools
                });
            }
            else if (target.id == 'experienceFields'){
                let experienceFields = this.state.experienceFieldsFilter;
                var indice = experienceFields.indexOf(idFind);        
                experienceFields.splice(indice, 1);
                this.setState({
                    experienceFieldsFilter : experienceFields
                });
            }
            else if (target.id == 'knowledgeArea'){
                let knowledgeArea = this.state.knowledgeAreaFilter;
                var indice = knowledgeArea.indexOf(idFind);        
                knowledgeArea.splice(indice, 1);
                this.setState({
                    knowledgeAreaFilter : knowledgeArea
                });
            }
            else if (target.id == 'disciplines'){
                let disciplines = this.state.disciplinesFilter;
                var indice = disciplines.indexOf(idFind);        
                disciplines.splice(indice, 1);
                this.setState({
                    disciplinesFilter : disciplines
                }); 
            }
        }

        let dataFilter = {};
        let filter = false;

        if(this.state.learningStepsFilter.length > 0){
            dataFilter.learningStepsFilter = this.state.learningStepsFilter
            filter = true;
        }
        if(this.state.digitalToolsFilter.length > 0){
            dataFilter.digitaltoolsFilter = this.state.digitalToolsFilter
            filter = true;
        }
        if(this.state.experienceFieldsFilter.length > 0){
            dataFilter.experienceFieldsFilter = this.state.experienceFieldsFilter
            filter = true;
        }
        if(this.state.knowledgeAreaFilter.length > 0){
            dataFilter.knowledgeAreaFilter = this.state.knowledgeAreaFilter
            filter = true;
        }        
        if(this.state.disciplinesFilter.length > 0){
            dataFilter.disciplinesFilter = this.state.disciplinesFilter
            filter = true;
        }
                
        this.props.setLoadingCallback(true);

        if(filter){
            dataFilter.page = 0;
            dataFilter.pageSize = 1000;

            LearningPlanServices.search(dataFilter, (data) => {
                this.props.setplansCallback(data); 
                this.props.setLoadingCallback(false);
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            });
        }else{
            LearningPlanServices.getData(this.state.page, this.state.totalItens, (data) => {
                this.props.setplansCallback(data);
                this.props.setLoadingCallback(false);
                this.preencherModel()
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            });
        }
        
    }

    handleErrorMessage = (err) => this.showErrorMessage(err.message || 'Ocorreu um erro durante a operação');

    componentDidMount() {

        this.getLearningSteps();
        this.getDigitalTools();
        this.getExperienceFields();
        this.getLearningPlans();
        this.getDisciplines();
        this.getknowledgeArea();
    }

    render() {
        const learningSteps = this.state.learningSteps;
        const digitalTools = this.state.digitalTools;
        const experienceFields = this.state.experienceFields;
        const disciplines= this.state.disciplines;
        const knowledgeArea = this.state.knowledgeArea;
        
        return (
            <div className="menu-wrapper" role="navigation">
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <div className="container"/>
                    <ul className="nav nav-mega" role="menubar">
                        <li role="menuitem">
                            <a href="#" className="filter">
                                Filtrar por categoria
                                <i className="fas fa-sort-down"/>
                            </a>
                            <div className="mega-menu" aria-hidden="true" role="menu">                                
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="learningStep-tab" data-bs-toggle="tab" data-bs-target="#learningStep" type="button" role="tab" aria-controls="learningStep" aria-selected="true">Etapas de ensino</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="digitalTools-tab" data-bs-toggle="tab" data-bs-target="#digitalTools" type="button" role="tab" aria-controls="digitalTools" aria-selected="false">Ferramentas digitais</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="experienceFields-tab" data-bs-toggle="tab" data-bs-target="#experienceFields" type="button" role="tab" aria-controls="experienceFields" aria-selected="false">Campos de experiência</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="knowledgeArea-tab" data-bs-toggle="tab" data-bs-target="#knowledgeArea" type="button" role="tab" aria-controls="knowledgeArea" aria-selected="false">Áreas de conhecimento</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="disciplines-tab" data-bs-toggle="tab" data-bs-target="#disciplines" type="button" role="tab" aria-controls="disciplines" aria-selected="false">Disciplinas</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="learningStep" role="tabpanel" aria-labelledby="learningStep-tab">
                                    <form className="me-select">
                                        <ul id="me-select-list">
                                            {
                                                learningSteps.map((value, index) => {
                                                    return (
                                                        <li key={value.id}>                                                            
                                                            <Label check>
                                                                <Input type="checkbox" id='learningSteps' key={value.id} onChange={({target}) => this.filterLearningPlans(value, target)} /> {value.name}
                                                            </Label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="digitalTools" role="tabpanel" aria-labelledby="digitalTools-tab">
                                    <form className="me-select">
                                        <ul id="me-select-list">
                                            {
                                                digitalTools.map((value, index) => {
                                                    return (

                                                        <li key={value.id}>
                                                            <Label check>
                                                                <Input type="checkbox" id='digitaltools' key={value.id} onChange={({target}) => this.filterLearningPlans(value, target)} /> {value.name}
                                                            </Label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="experienceFields" role="tabpanel" aria-labelledby="experienceFields-tab">
                                    <form className="me-select">
                                        <ul id="me-select-list">
                                            {
                                                experienceFields.map((value, index) => {
                                                    return (

                                                        <li key={value.id}>
                                                            <Label check>
                                                                <Input type="checkbox" id='experienceFields' key={value.id} onChange={({target}) => this.filterLearningPlans(value, target)} /> {value.name}
                                                            </Label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="knowledgeArea" role="tabpanel" aria-labelledby="knowledgeArea-tab">
                                    <form className="me-select">
                                        <ul id="me-select-list">
                                            {
                                                knowledgeArea.map((value, index) => {
                                                    return (

                                                        <li key={value.id}>
                                                            <Label check>
                                                                <Input type="checkbox" id='knowledgeArea' key={value.id} onChange={({target}) => this.filterLearningPlans(value, target)} /> {value.name}
                                                            </Label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="disciplines" role="tabpanel" aria-labelledby="disciplines-tab">
                                    <form className="me-select">
                                        <ul id="me-select-list">
                                            {
                                                disciplines.map((value, index) => {
                                                    return (

                                                        <li key={value.id}>
                                                            <Label check>
                                                                <Input type="checkbox" id='disciplines' key={value.id} onChange={({target}) => this.filterLearningPlans(value, target)} /> {value.name}
                                                            </Label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default FilterBar;