import React, {Component} from 'react';
import {Container, Row, Col} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import LearningPlanServices from "../../services/LearningPlans/LearningPlansServices"
import SkillCompetencesServices from '../../services/SkillCompetences/SkillCompetencesServices';
import {AlertContext} from "../../providers/AlertProvider";


class LearningPlansDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: null,
            id: null,
            bnccCapabilities: [],
            curriculumComponents: [],
            educationCapabilities: [],
            downloadUrl: '',
            description: '',
            subLevel: 1,
            listSkillCompetencesDisciplines: [],
            listSkillCompetencesExperienceFields: []
        };
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        if (params.id !== undefined) {
            LearningPlanServices.getDetails(params.id, (data) => {                
                if (data) {
                    this.setState({
                        id: params.id,
                        model: data,
                        description: data.name,
                        subLevel: data.learningStep.subLevel
                    });     
                }

                if(this.state.subLevel == 1){
                    this.getSkillCompetencesExperienceFieldsByLearningPlanId(data.id);
                }else{
                    this.getSkillCompetencesDisciplinesByLearningPlanId(data.id);
                }

            }, (err) => {
                debugger
                this.showErrorMessage(err.message || err.response.data.Error);
            });

        } else {
            this.showErrorMessage("Invalid");
        }
    }

    getSkillCompetencesDisciplinesByLearningPlanId = (id, state) => {
        
        this.setState({ loading: true });
        SkillCompetencesServices.getSkillCompetencesDisciplinesByLearningPlanId(id, (data) => {
            this.setState({
                listSkillCompetencesDisciplines: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    getSkillCompetencesExperienceFieldsByLearningPlanId = (id, state) => {
        
        this.setState({ loading: true });
        SkillCompetencesServices.getSkillCompetencesExperienceFieldsByLearningPlanId(id, (data) => {
            this.setState({
                listSkillCompetencesExperienceFields: data,
                pages: data.pages,
                loading: false
            });
            
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    handleDownload = (e) => {
        const id = this.state.id;
        LearningPlanServices.downloadMaterial(id).then((response) => {

            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;base64,' + response.data);
            element.setAttribute('download', id+".zip");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);


        }).catch((err) => {
            this.showErrorMessage(err);
        });
    };

    handlePrint = (e) => {
        window.print();
    };

    htmlAttachments = (attachments) => {
        let rawData = attachments;
        
        return rawData.map((item, index) => {
            let style = {};
            let imgUrl = "/img/i-anexo.png";

            if (item.mimeType.indexOf("image/") !== -1) {
                style = {
                    'background': `url(${item.url})`
                };
            }
            
            if (item.mimeType.indexOf("word") !== -1 || item.mimeType.indexOf("wordprocessingml") !== -1){
                imgUrl = "/img/i-word.png";
            }
            
            if (item.mimeType.indexOf("excel") !== -1 || item.mimeType.indexOf("spreadsheetml") !== -1){
                imgUrl = "/img/i-excel.png";
            }
            
            if (item.mimeType.indexOf("powerpoint") !== -1 || item.mimeType.indexOf("presentationml") !== -1){
                imgUrl = "/img/i-ppt.png";
            } 

            return (
                <section className="item">
                    <a href={item.url}>
                        {imgUrl !== "" ? <img src={imgUrl} alt={item.name}/> : <div className="archive" style={style}/>}
                        <p>{item.name}</p>
                    </a>
                </section>
            );
        })
    };

    htmlSkillCompetences(){    
        if(this.state.subLevel == 1){
            return(
                <p>                   
                    {this.state.listSkillCompetencesExperienceFields.length > 0 && this.state.listSkillCompetencesExperienceFields.map((item) => {                                                                                            
                        return(
                                <div>
                                    <div>&nbsp;</div>
                                    <div className="word-highlighted">{item.name}</div>
                                    {item.listSkillCompetences.map((ItemSkillCompetence) => {
                                         return(
                                            <div>
                                                <div>
                                                    <b>({ItemSkillCompetence.skillCompetencesCode})</b>
                                                </div>
                                                <div>
                                                    {ItemSkillCompetence.name}
                                                </div>
                                            </div>
                                         )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </p>
            )
        }else{
            return(
                <p>
                { this.state.listSkillCompetencesDisciplines.length > 0 && this.state.listSkillCompetencesDisciplines.map((item) => {                                                                                            
                        return(
                            <div value={item.id}>                                
                                <div className="word-highlighted">{item.knowledgeAreaName}</div>                          
                                { item.disciplines.map((itemDisciplina) => {    
                                    return(
                                        <div>                                            
                                            <b>{itemDisciplina.name}</b>                                          
                                            { itemDisciplina.skillCompetences.map((ItemSkillCompetence) => { 
                                              return(                                                
                                                <div>                                                    
                                                    <div>
                                                        <b>({ItemSkillCompetence.skillCompetencesCode})</b>
                                                    </div>
                                                    <div>
                                                        {ItemSkillCompetence.name}
                                                    </div>
                                                    <div>&nbsp;</div>
                                                </div>
                                                )          
                                                })
                                            }
                                        </div>
                                    )                                        
                                    })
                                }
                            </div> 
                        )
                    })
                }
                </p>
            )
        }
    }

    render() {
        
        const plan = this.state.model;

        if (!plan) {
            return <section className="container">
                <section className="sg-loading">
                    <div className="lds-ring">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <p>Carregando...</p>
                </section>
            </section>
        }

        return (
            <div>
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <div className="breadcrumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-9">
                                <Link to="/">Home</Link> 
                                 &nbsp;
                                 &nbsp;
                                 {plan.name}
                            </div>
                            <div className="col-3">
                                <Link to="/">{'< Voltar'}</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <Container className="detalhes box-white">
                   
                    <Row className="row">                        
                        <Col xs={8}>
                            <h1>{plan.name}</h1>
                            <h3>Objetivo geral</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.generalObjectives }} ></div>
                            </p>
                            <h3>Objetivos específicos</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.specificObjectives }} ></div>
                            </p>
                            <h3>Estratégias de aprendizagem</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.learningStrategies }} ></div>
                            </p>
                            <h3>Recursos didáticos</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.didacticResources }} ></div>
                            </p>
                            <h3>Avaliação</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.assessment }} ></div>
                            </p>
                            <h3>Referências</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.references }} ></div>
                            </p>
                            
                            
                            <Col xs={12} className="anexos">
                           
                            {this.state.model.learningPlansAttachments.length > 0 && (<h3>Anexos</h3>)}
                            <ul className="list-img">
                                <li>
                                    {this.htmlAttachments(this.state.model.learningPlansAttachments)}
                                </li>
                            </ul>
                        </Col>                                
                        </Col>                                                      
                        <Col xs={4} className="detalhes-dir">                            
                                <img src="/img/i-download.png" onClick={() => this.handleDownload()}/>
                                <img src="/img/i-print.png" onClick={() => this.handlePrint()}/>
                            <h3>Etapa de ensino</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.learningStep.name }} ></div>
                            </p>
                            <h3>Habilidades e competências relacionados</h3>
                            {this.htmlSkillCompetences()}
                            <h3>Palavras-chave</h3>
                            <p>
                                <div className="words-chips" dangerouslySetInnerHTML={{ __html: this.state.model.searchKey }} ></div>
                            </p>
                            <h3>Duração</h3>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: this.state.model.duration }} ></div>
                            </p>
                            
                            {this.state.model.learningPlansDigitalTools.length > 0 && (<h3>Ferramentas digitais</h3>)}
                            <p>{this.state.model.learningPlansDigitalTools.length > 0 && this.state.model.learningPlansDigitalTools
                                .map(t => t.name)
                                .reduce((prev, curr) => [prev, ', ', curr])}</p>
                                {this.state.model.learningPlansRelateds.length > 0}
                                
                            {this.state.model.learningPlansRelateds.length > 0 && (<h3>Roteiros relacionados</h3>)}
                            {this.state.model.learningPlansRelateds.length > 0 && this.state.model.learningPlansRelateds.map((item) => {                                                                                            
                                    return(
                                        <div value={item.id}>
                                            <a href={`/${item.learningPlanReferencesId}`}>{item.name}</a>                                                                                      
                                        </div> 
                                                                                    
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withRouter(LearningPlansDetails);