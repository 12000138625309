import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import LearningPlanServices from "../../services/LearningPlans/LearningPlansServices"
import {range} from "../../core/ranges/ranges";
import LearningPlanRatingModal from "./LearningPlanRatingModal";
import LearningPlansUserRatingServices from "../../services/LearningPlansUserRating/LearningPlansUserRatingServices";

class LearningPlansCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planId: props.planId,
            learningPlanName: props.learningPlanName,
            downloadUrl: '',
            stars: 0
        }
    }

    setCloseModal = () =>{        
        this.setState({
            showModal: false
        })
    }

    setRedirect = () => {        
        this.setState({
            redirect: true
        })
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={`/${this.state.planId}`} />
        }
    };

    handleDownload = (e) => {
        const id = this.props.planId;
        LearningPlanServices.downloadMaterial(id).then((response) => {

            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;base64,' + response.data);
            element.setAttribute('download', id+".zip");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);


        }).catch((err) => {
            this.showErrorMessage(err);
        });
    };

    renderModal = () => {        
        if (this.state.showModal) {
            return <LearningPlanRatingModal planId={this.state.planId} learningPlanName={this.state.learningPlanName} stars={this.state.stars} showModal={this.state.showModal} onPressClose={() => {this.setCloseModal()}}/>
        }
    };

    componentDidMount() {
        if (this.props.planId !== undefined) {
            LearningPlansUserRatingServices.getLearningPlansUserRating(this.props.planId, (data) => { 
                if(data != ""){                               
                    this.setState({
                        stars: data.ratingStars,
                        description : data.description,
                        isUpdate: true,
                        ratingId: data.id
                    })
                }

            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })
        }
    }

    changeStar = (index) => {
        debugger
        this.setState({
            stars: index + 1,
            showModal: true
        });
    };

    renderStars = (stars) => {
        const selectedStars = this.state.stars;
        const style = {
            color: 'gold'
        };
        return [...range(1, 5)].map((value, index) => {
            return <i className={`${((value <= selectedStars) ? "fas" : "far")} fa-star`}
                      style={style} onClick={() => this.changeStar(index)}/>
        });
    };

    render() {
        return (
            <a href="#" className="a-grid" data-path-hover="m 180,65.57627 -180,0 L 0,0 180,0 z">
                {this.renderRedirect()}
                {this.renderModal()}
                <figure>
                    <svg className="svg" viewBox="0 0 180 320" preserveAspectRatio="none">
                        <path d="M 180,160 0,218 0,0 180,0 z"/>
                    </svg>
                    <figcaption className="course">
                        <h2 onClick={this.setRedirect}>{this.props.learningPlanName}</h2>
                        <section onClick={this.setRedirect} className="chips bg-purple color-white">
                            {this.props.learninStepName}
                        </section>
                        <section className="range">
                            {this.renderStars(this.props.ratingstars)}
                        </section>
                        <section className="range-in" data-toggle="modal">
                            <p>O que você achou?</p>
                            {this.renderStars()}
                        </section>
                        <button className="btn-white-round"
                                onClick={this.setRedirect}>Ver detalhes</button>
                        <section className="fig-footer">
                           <a onClick={() => this.handleDownload()}>
                             <img src="img/i-download.png" alt='Download'/>
                           </a>
                        </section>
                    </figcaption>
                </figure>
            </a>
        )
    }
}

export default LearningPlansCard;