import React, {Component} from 'react';
import {Container} from "reactstrap";
import LearningPlansCard from "../LearningPlans/LearningPlansCard";
import SearchBar from "./SearchBar";
import FilterBar from "./FilterBar";
// import LearningPlanRatingModal from "../Course/LearningPlanRatingModal";
import { AlertContext } from "../../providers/AlertProvider";
import ServicesLearningPlans from "../../services/LearningPlans/LearningPlansServices";
import InfiniteScroll from 'react-infinite-scroller';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            learningPlans: [],
            totalItens: 8,
            page: 0,
            showLoadingBottom: false,
            loading: true
        }

        this.setplans = this.setplans.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.loadMore);
        this.getLearningPlans(this.state.page, this.state.totalItens);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.loadMore);
    }

    loadMore = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight && this.state.showLoadingBottom == false) {                                   
            this.setState({
                showLoadingBottom: true,
                page: (this.state.page + 1)
            });           

            this.getLearningPlans(this.state.page, this.state.totalItens);

        }else{
            this.setState({
                showLoadingBottom: false
            });
        }

        this.render();
    }

    setplans(learningPlans) {        
        this.setState({
            learningPlans: learningPlans
        })
      }

    setLoading(statusLoading) {        
        this.setState({
            loading: statusLoading
        })
    }
   
    getLearningPlans(page, totalItens){        
        ServicesLearningPlans.getData(page, totalItens, (data) => {
            if(data.length > 0){
                this.setState(prevState => ({               
                    learningPlans: [...prevState.learningPlans, ...data].sort((a,b) => (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)),
                    loading: false,
                    showLoadingBottom: false
                }));            
            }else{
                this.setState({
                    showLoadingBottom: false
                });
            }
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    }

    loadingBottom = () => {
        if(this.state.showLoadingBottom){
            return(
                <section className="sg-loading-bottom">
                    <div className="lds-ring">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <p>Carregando mais itens...</p>
                </section>
                )
        }
    }

    renderLeaningPlans(learningPlans){
        
        if (learningPlans.length == 0) {
            return(
                <section className="container">
                    <section className="sg-loading">                        
                        <p>Nenhum dado encontado.</p>
                    </section>
                </section>
            )
        }else{
            return(
                <Container>
                    <section id="grid" className="grid clearfix">                           
                        {learningPlans.filter(x => x.learningStep != null && x.learningStep != undefined).map((value, i) =>
                                <LearningPlansCard planId={value.id} learningPlanName={value.name} RatingStars={value.RatingStars}
                                learninStepName={value.learningStep.name}/>)}       
                                {this.loadingBottom()}                 
                    </section>
                    
                </Container>
            )
        }
    }

    renderLoading(){
        if(this.state.loading){
            return (
                <section className="container">
                    <section className="sg-loading">
                        <div className="lds-ring">
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                        </div>
                        <p>Carregando...</p>
                    </section>
                </section>
            )
        }
    }

    render() {
        const learningPlans = this.state.learningPlans;
        
        

        return (
            <div>
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <SearchBar setplansCallback={this.setplans}/>
                <FilterBar setplansCallback={this.setplans} setLoadingCallback={this.setLoading}/>
                {this.renderLoading()}
                {this.state.loading == false && (this.renderLeaningPlans(learningPlans))}                    
            </div>
        )        
    }
}

export default Home;

